import Menu, { MenuProps } from 'antd/lib/menu';
import { useState } from 'react';
import styles from './App.module.less';
import logo from './assets/logo.png';
import menu from './assets/menu.svg';
import PremiumContent from './assets/premium-content.png';
import CarrierBilling from './assets/carrier-billing.png';
import TrafficAcquisition from './assets/traffic-acquisition.png';
import GAME from './assets/GAME.png';
import NOVEL from './assets/Novel.png';
import COMICS from './assets/COMICS.png';
import SOCIAL from './assets/SOCIAL.png';
import PORTABLE_CINEMA from './assets/PORTABLE-CINEMA.png';
import Rectangle from './assets/Rectangle.svg';
import MapImg from './assets/map.png';
import LogoBig from './assets/logo-big.png';
import { Carousel, Form, Input, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const items: MenuProps['items'] = [
  {
    label: 'About',
    key: 'About',
  },
  {
    label: <a href="#What We Do">What We Do</a>,
    key: 'What_We_Do',
  },
  {
    label: <a href="#Premium Content">Premium Content</a>,
    key: 'Premium_Content',
  },
  {
    label: <a href="#Company Introduction">Company Introduction</a>,
    key: 'Company_Introduction',
  },
  {
    label: <a href="#Partners">Partners</a>,
    key: 'Partners',
  },
  {
    label: <a href="#Feedback">Feedback</a>,
    key: 'Feedback',
  },
];

const partner_logo = [
  '/1_vodafone.png',
  '/2_T-Mobile.png',
  '/3_Orange.png',
  '/4_O2.png',
  '/5_Hutchison-3G.png',
  '/6_Slovak-Telekom.png',
  '/7_VIP-Mobile.png',
  '/8_Telekom-Srbjia.png',
  '/9_Romtelecom.png',
  '/10_A1.png',
  '/11_Telia.png',
  '/12_Tele2.png',
  '/13_Bite.png',
  '/14_Wind.png',
];

const vas_content = [
  'Joyverse owns a game R&D team with dozens of engineers, has self-developed more than 300 games, jointly operates with senior game manufacturers in the industry. We have carried out joint operations with first-tier game companies including 37 Games, 2345 Network, 4399 Games, continuously cooperates for thousands of games.',
  'Joyverse also steps into the Webnovel & WebComics industry, owns the copyrights of tens of thousands of webnovels, provides copyright agency services.',
  'Joyverse also steps into the Webnovel & WebComics industry, owns the copyrights of tens of thousands of webnovels, provides copyright agency services.',
  'Joyverse also steps into the Webnovels industry, owns the copyrights of tens of thousands of webnovels, provides copyright agency services.',
  'Joyverse cooperate with many video copyright owners and own the copyright usage rights of many movies',
];

interface UserForm {
  username: string;
  email: string;
  question: string;
}

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [userForm, setUserForm] = useState<UserForm>({
    username: '',
    email: '',
    question: '',
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [premiumIndex, setPremiumIndex] = useState(1);

  function getPremiumStyle(index: number) {
    return index === premiumIndex
      ? styles['premium-content-active']
      : styles['premium-content'];
  }

  return (
    <div
      className={styles.app}
      style={{
        overflow: showMenu ? 'hidden' : 'auto',
      }}
    >
      {contextHolder}
      <div className={styles.nav}>
        <img src={logo} alt="" />
        <img src={menu} alt="" onClick={() => setShowMenu(!showMenu)} />
      </div>

      {showMenu && (
        <div className={styles.menu}>
          <Menu items={items} onClick={() => setShowMenu(false)} />
        </div>
      )}
      <div className={styles['mobile-content-card']}>
        <div className={styles['mobile-content-title']}>
          Mobile Content Provider
        </div>
        <div className={styles['mobile-content-content']}>
          Focus on developing quality content and monetizing
        </div>
      </div>
      <div className={styles['what-we-do-bg']} id="What We Do">
        <div className={styles['what-we-do-card']}>
          <div className={styles['what-we-do-title']}>What We Do</div>
          <div className={styles['what-we-do-sub-list']}>
            <div className={styles['what-we-do-sub-div']}>
              <img src={PremiumContent} alt="" />
              <div className={styles['what-we-do-sub-title']}>
                Premium Content
              </div>
              <div className={styles['what-we-do-sub-content']}>
                We have carried out joint operations with first-tier game
                companies including 37 Games, 2345 Network, 4399 Games,
                continuously cooperates for thousands of games
              </div>
            </div>
            <div className={styles['what-we-do-sub-div']}>
              <img src={CarrierBilling} alt="" />
              <div className={styles['what-we-do-sub-title']}>
                Carrier Billing
              </div>
              <div className={styles['what-we-do-sub-content']}>
                We provide monetisation solutions, such as Ads-Support and
                Subscription with local telcos/carriers, to help our partners
                earn financial benefits without any upfront costs.
              </div>
            </div>
            <div className={styles['what-we-do-sub-div']}>
              <img src={TrafficAcquisition} alt="" />
              <div className={styles['what-we-do-sub-title']}>
                Traffic Acquisition
              </div>
              <div className={styles['what-we-do-sub-content']}>
                We works directly with well known aggregators as well as local
                telcos/carriers, ensure flows are most optimized and connections
                are stable and protected with anti-fraud system
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['premium-content-card']} id="Premium Content">
        <div>
          <div className={styles['premium-content-title']}>
            VAS/ Premium Content
            <div className={styles['premium-content-line']}></div>
          </div>
          {/* <div className={styles['premium-content-line']}></div> */}
        </div>

        <div
          className={styles['premium-content-first-content']}
          style={{
            justifyContent: 'space-between',
          }}
        >
          <div
            className={getPremiumStyle(1)}
            onClick={() => setPremiumIndex(1)}
          >
            <img src={GAME} alt="" />
            <span>GAME</span>
          </div>
          <div
            onClick={() => setPremiumIndex(2)}
            className={getPremiumStyle(2)}
          >
            <img src={NOVEL} alt="" />
            <span>WEB NOVEL</span>
          </div>
          <div
            onClick={() => setPremiumIndex(3)}
            className={getPremiumStyle(3)}
          >
            <img src={COMICS} alt="" />
            <span>COMICS</span>
          </div>
        </div>
        <div
          className={styles['premium-content-first-content']}
          style={{
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => setPremiumIndex(4)}
            className={getPremiumStyle(4)}
          >
            <img src={SOCIAL} alt="" />
            <span>SOCIAL</span>
          </div>
          <span
            style={{
              width: '5%',
            }}
          />
          <div
            onClick={() => setPremiumIndex(5)}
            className={getPremiumStyle(5)}
          >
            <img src={PORTABLE_CINEMA} alt="" />
            <span>PORTABLE CINEMA</span>
          </div>
        </div>

        <div className={styles['premium-content-bottom-content']}>
          <img src={Rectangle} alt="" />
          <p>
            {premiumIndex}.{vas_content[premiumIndex - 1]}
          </p>
        </div>
      </div>
      <div className={styles['company-introduction']} id="Company Introduction">
        <img src={MapImg} alt="" />

        <div className={styles['company-introduction-title']}>
          Company Introduction
          <div></div>
        </div>

        <div className={styles['company-introduction-content']}>
          Joyverse is a diversified digital content provider dedicated to the
          development and operation of global Internet products for more than 7
          years. We now operate in more than 20 countries, have offices in
          Hangzhou, China (headquarters), Indonesia, Hong Kong and Nigeria, with
          more than 100 R&D and Operation employees.
        </div>

        <div id="Partners"></div>
        <Carousel
          dotPosition={'bottom'}
          autoplay
          dots={{
            className: styles['company-introduction-carousel'],
          }}
        >
          {partner_logo.map((item) => (
            <img src={`/partner_logo${item}`} alt="" key={item} />
          ))}
        </Carousel>
      </div>
      <div className={styles['make-money']} id="Feedback">
        <div className={styles['make-money-title']}>
          Make money with us
          <br /> by connecting more market
        </div>
        <div className={styles['make-money-line']}></div>
        <div className={styles['make-money-content']}>
          We offer profitable cooperation opportunities for companies and
          individuals able to provide stable mobile payment connections locally.
        </div>
        <button className={styles['make-money-button']}>
          Offer a connection
        </button>
      </div>
      <div className={styles['get-in-touch']}>
        <div className={styles['get-in-touch-title']}>
          Get in touch with us
          <div className={styles['get-in-touch-line']}></div>
        </div>
        <div className={styles['get-in-touch-content']}>
          If you have a question or a request for us, feel free to contact us
          using the form on the right.
        </div>
        <Form
          form={form}
          onFinish={() => {
            let _form = new FormData();
            _form.append('username', userForm.username);
            _form.append('email', userForm.email);
            _form.append('question', userForm.question);

            fetch('/api/touch_us', {
              method: 'POST',
              body: _form,
            })
              .then((res) => res.json())
              .then((res) => {
                try {
                  messageApi.open({
                    type: res.code === 200 ? 'success' : 'error',
                    content: res.msg,
                  });
                } catch {
                  alert(res.msg);
                }
              })
              .finally(() => {
                form.resetFields();
              });
          }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              placeholder="Your name"
              value={userForm.username}
              onChange={(e) => {
                setUserForm({
                  ...userForm,
                  username: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              placeholder="Your e-mail"
              onChange={(e) => {
                setUserForm({
                  ...userForm,
                  email: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item name="question">
            <TextArea
              placeholder="Your question"
              value={userForm.question}
              onChange={(e) => {
                setUserForm({
                  ...userForm,
                  question: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <button className={styles['get-in-touch-send']}>Send</button>
          </Form.Item>
        </Form>
      </div>
      <div className={styles['footer']}>
        <img src={LogoBig} alt="" />
        <div className={styles['footer-top-menu']}>
          <a href="#about">About</a>
          <a href="#What We Do">What We Do</a>
          <a href="#Premium Content">Premium Content</a>
        </div>
        <div className={styles['footer-bottom-menu']}>
          <a href="#Company Introduction">Company Introduction</a>
          <a href="#Partners">Partners</a>
          <a href="#Feedback">Feedback</a>
        </div>
        <div className={styles['footer-cookie']}>
          cookies | privacy | about us | contact
          <br />
          Copyright © joyverse. All rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default App;
